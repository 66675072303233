$(document).ready(function() {

	// PARALLAX EFFECT

	$(window).scroll(function() {
		var e = $(this).scrollTop(),
			t = $("html").height();
		curTop = 800 * (e / t) + 0, $("#banners-scroll").css("top", curTop + 0 + "px")
	});


	// SLIDER

	if ($('.slider_main_block').length) {

		$('.slider_main_block').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			dots: false,
			rtl: true,
			autoplay: true,
			pauseOnHover: false
		});

	}


	// OPEN/CLOSE LEFT ASIDE

	$('.left_side_wrapper .open_aside').on('click', function(e) {
		e.preventDefault();

		$('body').toggleClass('active');

		$('.left_side_wrapper').toggleClass('active');
		$(this).toggleClass('active');

	});


	// FAQ

	$('.faq_wrapper ul li > a').on('click', function(e) {
		e.preventDefault();

		$(this).toggleClass('active');
		$(this).siblings('.text').slideToggle(400);

	});

});